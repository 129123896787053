import React from 'react';
import ReactDOM from 'react-dom/client';
 
import PresidentSlide from './presidentsSlide.js';
 

const defRoot = document.getElementById('presidents_content')
const root = ReactDOM.createRoot(defRoot);
root.render(
 
    <PresidentSlide />
 
);

