// NOTICE: THIS IS ONLY A REFERENCE JSX SCRIPT FILE TO BE CONVERTED INTO AN ES6 FORMAT IN THE BASE FOLDER OF THE WEBSITE.
// IF ANY CHANGES ARE TO BE MADE, ENTER THIS COMMAND IN THE TERMINAL OF THE PROJECT FOLDER (e.g vs code terminal).s

//  TRANSPILE CODE 

// npx babel --watch back-end\react-scripts\global\Raw-Scripts --out-dir back-end\react-scripts\global\Transpiled --presets react-app/prod


// DEVELOPER NOTE: Contrary to the conventional HTML tags, Most sections of the home page were segmented into React Components, in addition to this. Most Built-in style properties
//  in certain tags were removed and made into their own independent style configurations within the stylesheet. (Personal Note: Please figure a more efficient way to deliver this integration.)



import React, {useState} from 'react';
 

export default function PresidentSlide(){
    const persons = [{ id: "1", name: "Abraham Chiam Ohayon", role: "Former President", desc: "The Former president of the Jewish Community of Malta", specialText: "'May his soul be in peace.'", picRef: "img/Home/AbrahamOhayon.png"},
   {id: "2" ,name: "Israel Ohayon", role: "Vice President", desc: "Serving as the Vice President of the Jewish Community of Malta.", specialText: " " ,picRef: "img/Home/Israel.JPG"},
   {id: "3", name: "Reuben Ohayon", role: "President", desc: "Serving as the current president of the Jewish Community of Malta",specialText: " " ,picRef: "img/Home/ReubenOhayon.png"},
   {id: "4", name: "Emmanuel George Tayar", role: "Former President", desc: "Former President of the Jewish Community of Malta", specialText: " ",picRef: "img/Home/GeorgeTayar.png"},
   {id: "5", name: "Sydney Berger", role: "Former President", desc: "Former President of the Jewish Community of Malta", specialText: " ", picRef: "img/Home/SydneyBerger.png"}
   ];
    
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleNextSlide = () => {
        setCurrentSlide(currentSlide === persons.length - 1 ? 0 : currentSlide + 1); // if current slide is last slide, jump to first slide, if it isn't go to next slide
    }
    const handlePreviousSlide = () => {
        setCurrentSlide(currentSlide === 0  ? persons.length - 1 : currentSlide - 1); // if current slide is first slide, jump to last slide, if it isn't, go to previous slide
    }
    return( 
        <div className={"president_main_div"}>
          <button onClick={handlePreviousSlide}>{'<'}</button>
    
          <img className={"president_main_pic_div"} src={persons[currentSlide].picRef}   alt={'President'}></img>
          <div className={"president_info_div"}>
             <header>{persons[currentSlide].name}</header>
             <h4 style={{marginTop: '5%'}}>{persons[currentSlide].desc}</h4>
             <p style={{marginTop: '10%', fontSize: '2.5vmin'}}>{persons[currentSlide].specialText}</p>
          </div>
          <button onClick={handleNextSlide}>{'>'}</button> 
        </div>
    );  
}
